<template>
  <div>
    <StudentGradeTable></StudentGradeTable>
  </div>
</template>

<script>
// import * as names from "@/store/names";
// import { mapGetters } from "vuex";

export default {
  name: "StudentGradeView",
  components: {
    StudentGradeTable: () =>
      import("@/components/new-dashboard/StudentGradeTable"),
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    // ...mapGetters({
    //    user: "getUser",
    //    institution: "getInstitution",
    // }),
  },
  methods: {},
  mounted() {},
  watch: {
    user() {},
  },
  created() {},
};
</script>

<style scoped>
</style>